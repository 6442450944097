import React from 'react'

const Footer: React.FC = () => {
  return (
    <footer style={{ padding: '10px', textAlign: 'center', backgroundColor: '#f8f9fa', borderTop: '1px solid #ddd' }}>
      <p>Copyright © NEVERBE Corp. All Rights Reserved.</p>
    </footer>
  )
}

export default Footer
