import React, { createContext, useContext } from 'react'
import axiosInstance from '@/utils/axiosInstance'

const AxiosContext = createContext(axiosInstance)

export const AxiosProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <AxiosContext.Provider value={axiosInstance}>
      {children}
    </AxiosContext.Provider>
  )
}

export const useAxios = () => useContext(AxiosContext)
