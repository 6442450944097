import {
  Label,
  FormGroup,
  Input,
  Anchor,
  Button,
  Text,
  Row,
  Select,
  Col,
  InputGroupAddon,
  InputGroup,
} from '@/components'
import Radio from '@/components/forms/form-elements/radio'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { hasKey } from '@/shared/methods'
import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledDivider,
  StyledBottomText,
} from './style'
import { fontSize, width } from 'styled-system'
import { useState } from 'react'
import { AlignCenter } from 'react-feather'

interface IFormValues {
  id: string
  password: string
  name: string
  last_name: string
}

const SigninForm = () => {
  const navigate = useNavigate()
  const isLogin = true

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>()

  const [selectedEmail, setSelectedEmail] = useState('')

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    alert(JSON.stringify(data, null))
  }

  const selectHandler = (data: any) => {
    console.log('data:', data)
    setSelectedEmail(data.target.value)
  }

  const buttonHandler = (pathName: string) => {
    console.log('pathName:', pathName)
    if (isLogin) {
      switch (pathName) {
        case 'complete':
          return navigate('/member/completeMember')
        case 'cancle':
          return navigate('/main')
        default:
          return navigate('/member')
      }
    }
  }

  return (
    <StyledWrap>
      <StyledTitle>네버비 회원가입</StyledTitle>
      <StyledDesc>회원가입 - 정보입력 </StyledDesc>
      <form action="#" onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="id">
            아이디
          </Label>
          <Row padding="15px">
            <Input
              id="id"
              name="id"
              type="id"
              width="40%"
              placeholder="아이디를 입력해 주세요."
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button type="submit" color="dark" active width="100px">
              중복확인
            </Button>
          </Row>
        </FormGroup>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="password">
            비밀번호
          </Label>
          <Input
            id="password"
            name="password"
            type="password"
            placeholder="비밀번호를 입력해 주세요."
          />
        </FormGroup>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="password_confm">
            비밀번호 확인
          </Label>
          <Input
            id="password_confm"
            name="password_confm"
            type="text"
            placeholder="비밀번호 확인을 입력해 주세요."
          />
        </FormGroup>
        <p style={{ textAlign: 'left' }}>
          <b>사용자 기본정보</b>
        </p>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="name">
            이름
          </Label>
          <Input
            id="name"
            name="name"
            type="text"
            placeholder="이름을 입력해 주세요."
          />
        </FormGroup>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="nicname">
            닉네임
          </Label>
          <Input
            id="nicname"
            name="nicname"
            type="text"
            placeholder="닉네임을 입력해 주세요."
          />
        </FormGroup>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="nicname">
            성별
          </Label>
          <Row>
            <Radio id="radio1" name="sexRadioYn" label="남성"></Radio>
            <Radio id="radio2" name="sexRadioYn" label="여성"></Radio>
          </Row>
        </FormGroup>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="nicname">
            생년월일
          </Label>
          <Row>
            <Input
              placeholder="YYYY"
              mb="10px"
              id="birthYear"
              name="birthYear"
              width="100px"
            />
            <p>&nbsp;년&nbsp;</p>
            <Input
              placeholder="MM"
              mb="10px"
              id="birthMonth"
              name="birthMonth"
              width="50px"
            />
            <p>&nbsp;월&nbsp;</p>
            <Input
              placeholder="DD"
              mb="10px"
              id="birthDay"
              name="birthDay"
              width="50px"
            />
            <p>일</p>
          </Row>
        </FormGroup>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="nicname">
            이메일
          </Label>
          <Row>
            <Input mb="10px" id="email1" name="email1" width="250px" />
            <p>&nbsp;@&nbsp;</p>
            <Input
              mb="10px"
              id="email2"
              name="email2"
              width="200px"
              value={selectedEmail}
            />
            <Col col>
              <Select
                id="selEmail"
                name="selEmail"
                width="200px"
                onChange={selectHandler}
              >
                <option value="">직접입력</option>
                <option value="chol.com">chol.com</option>
                <option value="dreamwiz.com">dreamwiz.com</option>
                <option value="empal.com">empal.com</option>
                <option value="freechal.com">freechal.com</option>
                <option value="hanmir.com">hanmir.com</option>
                <option value="hanafos.com">hanafos.com</option>
                <option value="hotmail.com">hotmail.com</option>
                <option value="korea.com">korea.com</option>
                <option value="lycos.co.kr">lycos.co.kr</option>
                <option value="nate.com">nate.com</option>
                <option value="naver.com">naver.com</option>
                <option value="netian.com">netian.com</option>
                <option value="paran.com">paran.com</option>
                <option value="yahoo.co.kr">yahoo.co.kr</option>
              </Select>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="nicname">
            SMS서비스
          </Label>
          <Row>
            <Radio
              id="radio3"
              name="smsReceiveYn"
              label="수신합니다."
              checked
            ></Radio>
            <Radio id="radio4" name="smsReceiveYn" label="거부합니다."></Radio>
          </Row>
        </FormGroup>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="job">
            직업
          </Label>
          <Row>
            <Select id="selJob" name="selJob" width="300px">
              <option value="">직업을 선택해 주세요</option>
              <option value="교육">교육</option>
              <option value="연구개발">연구개발</option>
              <option value="법률">법률</option>
              <option value="회계.세무">회계/세무</option>
              <option value="숙박.음식">숙박/음식</option>
              <option value="오락.문화.스포츠">오락/문화/스포츠</option>
              <option value="통신">통신</option>
              <option value="운송.물류">운송/물류</option>
              <option value="건설">건설</option>
              <option value="농업.임업.광업.수산업">
                농업/임업/광업/수산업
              </option>
              <option value="방송.언론.광고">방송/언론/광고</option>
              <option value="유통.무역">유통/무역</option>
              <option value="의약">의약</option>
              <option value="의료기기">의료기기</option>
              <option value="개인병원">개인병원</option>
              <option value="종합병원">종합병원</option>
              <option value="자영업">자영업</option>
              <option value="정부">정부</option>
              <option value="비영리.사회복지.행정">비영리/사회복지/행정</option>
              <option value="제1금융">제1금융</option>
              <option value="제2금융">제2금융</option>
              <option value="제조업1(금속.제철)">제조업1(금속/제철)</option>
              <option value="제조업2(의복.섬유.목재)">
                제조업2(의복/섬유/목재)
              </option>
              <option value="제조업3(석유.화학.전기)">
                제조업3(석유/화학/전기)
              </option>
              <option value="컴퓨터.인터넷">컴퓨터/인터넷</option>
              <option value="골프업계">골프업계</option>
              <option value="무직">무직</option>
              <option value="기타">기타</option>
            </Select>
          </Row>
        </FormGroup>
        <p style={{ textAlign: 'left' }}>
          <b>사용자 상세 정보</b>
        </p>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="homeAddress">
            자택 주소
          </Label>
          <Input
            placeholder="우편번호"
            mb="10px"
            id="homeAddrNum"
            name="homeAddrNum"
            width="200px"
          />
          <InputGroup>
            <Input
              mb="10px"
              placeholder="기본주소"
              id="homeAddr"
              name="homeAddr"
            />
            <Input
              mb="10px"
              placeholder="상세주소"
              id="homeAddr2"
              name="homeAddr2"
            />
            <InputGroupAddon>
              <Button variant="outlined" color="light">
                주소검색
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="homeTel">
            자택 전화 번호
          </Label>
          <Row>
            <Select id="homeTel" name="homeTel" width="100px">
              <option value="02">02</option>
              <option value="031">031</option>
              <option value="032">032</option>
              <option value="033">033</option>
              <option value="041">041</option>
              <option value="042">042</option>
              <option value="043">043</option>
              <option value="051">051</option>
              <option value="052">052</option>
              <option value="053">053</option>
              <option value="054">054</option>
              <option value="055">055</option>
              <option value="061">061</option>
              <option value="062">062</option>
              <option value="063">063</option>
              <option value="064">064</option>
              <option value="070">070</option>
            </Select>
            <p>&nbsp;-&nbsp;</p>
            <Input mb="10px" id="homeTel2" name="homeTel2" width="100px" />
            <p>&nbsp;-&nbsp;</p>
            <Input mb="10px" id="homeTel3" name="homeTel3" width="100px" />
          </Row>
        </FormGroup>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="mobNum">
            휴대 전화 번호
          </Label>
          <Row>
            <Select id="mobNum" name="mobNum" width="100px">
              <option value="010">010</option>
              <option value="011">011</option>
              <option value="016">016</option>
              <option value="017">017</option>
              <option value="018">018</option>
              <option value="019">019</option>
            </Select>
            <p>&nbsp;-&nbsp;</p>
            <Input mb="10px" id="mobNum2" name="mobNum2" width="100px" />
            <p>&nbsp;-&nbsp;</p>
            <Input mb="10px" id="mobNum3" name="mobNum3" width="100px" />
          </Row>
        </FormGroup>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="jobAddress">
            직장 주소
          </Label>
          <Input
            placeholder="우편번호"
            mb="10px"
            id="jobAddress"
            name="jobAddress"
            width="200px"
          />
          <InputGroup>
            <Input
              mb="10px"
              placeholder="기본주소"
              id="jobAddress2"
              name="jobAddress2"
            />
            <Input
              mb="10px"
              placeholder="상세주소"
              id="jobAddress3"
              name="jobAddress3"
            />
            <InputGroupAddon>
              <Button variant="outlined" color="light">
                주소검색
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="jobName">
            직장명
          </Label>
          <Input
            id="jobName"
            name="jobName"
            type="text"
            placeholder="직장명을 입력해 주세요."
          />
        </FormGroup>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="department">
            부서
          </Label>
          <Input
            id="department"
            name="department"
            type="text"
            placeholder="부서를 입력해 주세요."
          />
        </FormGroup>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="position">
            직위
          </Label>
          <Input
            id="position"
            name="position"
            type="text"
            placeholder="직위를 입력해 주세요."
          />
        </FormGroup>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="jobTel">
            자택 전화 번호
          </Label>
          <Row>
            <Select id="jobTel" name="jobTel" width="100px">
              <option value="02">02</option>
              <option value="031">031</option>
              <option value="032">032</option>
              <option value="033">033</option>
              <option value="041">041</option>
              <option value="042">042</option>
              <option value="043">043</option>
              <option value="051">051</option>
              <option value="052">052</option>
              <option value="053">053</option>
              <option value="054">054</option>
              <option value="055">055</option>
              <option value="061">061</option>
              <option value="062">062</option>
              <option value="063">063</option>
              <option value="064">064</option>
              <option value="070">070</option>
            </Select>
            <p>&nbsp;-&nbsp;</p>
            <Input mb="10px" id="jobTel2" name="jobTel2" width="100px" />
            <p>&nbsp;-&nbsp;</p>
            <Input mb="10px" id="jobTel3" name="jobTel3" width="100px" />
          </Row>
        </FormGroup>

        <FormGroup mb="20px" margin={'AlignCenter'}>
          <Button
            type="submit"
            color="primary"
            width="100px"
            onClick={() => buttonHandler('complete')}
          >
            확인
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            type="submit"
            color="light"
            width="100px"
            onClick={() => buttonHandler('cancle')}
          >
            취소
          </Button>
        </FormGroup>
        <StyledBottomText>
          Don&apos;t have an account? <Anchor path="/signin">Sign In</Anchor>
        </StyledBottomText>
      </form>
    </StyledWrap>
  )
}

export default SigninForm
