import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios'

// 타입 확장
interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean
}

interface ErrorResponse {
  message: string
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: Number(process.env.REACT_APP_DEFAULT_TIMEOUT),
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // 쿠키를 요청에 포함시킴
})

axiosInstance.interceptors.request.use(
  (config) => {
    const access_token = sessionStorage.getItem('Access-Token')
    if (access_token) config.headers['Authorization'] = `Bearer ${access_token}`
    return config
  },
  (err) => Promise.reject(err),
)

axiosInstance.interceptors.response.use(
  (response) => response,
  async (err: AxiosError<ErrorResponse>) => {
    if (err.response) {
      const status = err.response.status
      const message =
        err.response.data?.message || err.message || 'Unknown error'
      const originalRequest = err.config as CustomAxiosRequestConfig

      if (status === 401) {
        if (originalRequest.url !== '/auth/login') {
          // 401 에러 발생 시
          try {
            const refreshResponse = await axios.post(
              '/auth/refresh-token',
              {},
              { withCredentials: true },
            )

            if (
              refreshResponse.status === 200 &&
              refreshResponse.data?.accessToken
            ) {
              // 리프레시 토큰 요청 성공
              const newAccessToken = refreshResponse.data.accessToken
              sessionStorage.setItem('Access-Token', newAccessToken)
              originalRequest.headers['Authorization'] =
                `Bearer ${newAccessToken}`
              return axiosInstance(originalRequest) // 요청 재전송
            } else {
              // 리프레시 토큰 요청 실패
              console.error('Refresh token request failed.', refreshResponse)
              sessionStorage.removeItem('Access-Token')
              // window.location.href = '/main' // 로그인 페이지로 이동
              return Promise.reject(new Error('Refresh token request failed'))
            }
          } catch (refErr) {
            // 리프레시 토큰 요청 중 오류 발생
            console.error('Error during refresh token request:', refErr)
            sessionStorage.removeItem('Access-Token')
            // window.location.href = '/main' // 로그인 페이지로 이동
            return Promise.reject(refErr)
          }
        } else {
          // 로그인 API 요청에서 401 에러 발생 시, 리프레시 로직을 건너뜀
          console.error(
            'Login failed:',
            err.response.data?.message || err.message,
          )
          return Promise.reject(err)
        }
      } else {
        console.error(`Error ${status}: ${message}`)
      }
    } else if (err.request) {
      console.error('No response received:', err.request)
    } else {
      console.error('Error setting up request:', err.message)
    }

    return Promise.reject(err)
  },
)

export default axiosInstance
