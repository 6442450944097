import { Input, Textarea } from '@/components'
import { ComentsInputBox, CommentsDetailBox } from './comments'
import { BoardHeader } from '../_components/boardHeader'
import { BoardCrudBar } from '../_components/boardCrudBar'

const DetailBoard: React.FC = () => {
  return (
    <div>
      <div>
        <BoardHeader />
      </div>
      <div
        style={{
          marginBottom: '10px',
        }}
      >
        <Input
          id=""
          name=""
          placeholder="제목을 입력하세요."
          state="success"
          feedbackText="Looks good!"
          disabled
        />
      </div>
      <div
        style={{
          marginBottom: '10px',
        }}
      >
        <Textarea
          id=""
          name=""
          placeholder="내용을 입력하세요."
          state="success"
          feedbackText="Looks good!"
          disabled
        />
      </div>
      <div
        style={{
          marginBottom: '20px',
        }}
      />
      <div style={{ marginBottom: '20px' }}>댓글 [20]</div>
      <CommentsDetailBox />
      <ComentsInputBox />
      <BoardCrudBar type={'detail'} />
    </div>
  )
}

export default DetailBoard
