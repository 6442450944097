import Board from '@/pages/board/board'
import DetailBoard from '@/pages/board/detail/detailBoard'
import InputBoard from '@/pages/board/input/inputBoard'
import Main from '@/pages/main/main'
import NewMember from '@/pages/member/agree/newMember'
import NewMember2 from '@/pages/member/newMember/newMember'
import Member from '@/pages/member/member'
import Sample from '@/pages/sample/sample'
import Event from '@/pages/event/event'
import CompleteMember from '@/pages/member/completeMember/completeMember'
import ErrorContainer from '@/components/error/error-404'

export const routes = [
  {
    path: '/',
    element: <h1>Home Page {process.env.REACT_APP_PROFILE}</h1>,
  },
  {
    path: '/main',
    element: <Main />,
    label: 'Home',
  },
  {
    path: '/board',
    element: <Board />,
    label: 'Board',
  },
  {
    path: '/sample',
    element: <Sample />,
    label: 'Documentation',
  },
  {
    path: '/member',
    element: <Member />,
    label: 'Member',
  },
  {
    path: '/member/agree',
    element: <NewMember />,
  },
  {
    path: '/member/newMember',
    element: <NewMember2 />,
  },
  {
    path: '/member/completeMember',
    element: <CompleteMember />,
  },
  {
    path: '/board/write',
    element: <InputBoard />,
  },
  {
    path: '/board/detail',
    element: <DetailBoard />,
  },
  {
    path: '*',
    element: <ErrorContainer />,
    label: '404',
  },
]
