import { Button } from '@/components'
import { DaumPostcodeData, usePostcodeSearch } from '@/hooks/usePostcodeSearch'
import React from 'react'

interface PostSampleProps {
  onAddressSelect?: (data: DaumPostcodeData) => void
}
const PostSample: React.FC<PostSampleProps> = ({ onAddressSelect }) => {
  const { data, handleOpenPopup } = usePostcodeSearch()

  const fullAddress = data
    ? `${data.address} ${data.buildingName ? `(${data.buildingName})` : ''}`
    : '주소를 검색해 주세요.'

  const handleAddressSelect = () => {
    if (onAddressSelect && data) {
      onAddressSelect(data)
    }
  }

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <Button color="warning" onClick={handleOpenPopup}>
        주소검색
      </Button>
      <div style={{ marginTop: '20px' }}>
        <strong>검색된 주소 :</strong> {fullAddress}
      </div>
    </div>
  )
}

export default PostSample
