import ListGroupItem from '@/components/ui/list-group/list-group-item'
import Table from '@/components/ui/table/table'
import React from 'react'
import Textarea from '@/components/forms/form-elements/textarea'
import Checkbox from '@/components/forms/form-elements/checkbox'
import Button from '@/components/ui/button/button'
import { buttonStyle, left } from 'styled-system'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Member: React.FC = () => {
  const navigate = useNavigate()
  const isLogin = true

  const buttonHandler = (pathName: string) => {
    console.log('pathName:', pathName)
    if (isLogin) {
      switch (pathName) {
        case 'agree':
          return navigate('/member/newMember')
        case 'cancle':
          return navigate('/main')
        default:
          return navigate('/member')
      }
    }
  }

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1 style={{ textAlign: 'left' }}>네버비 회원가입</h1>
      <p style={{ textAlign: 'left' }}>회원가입 -약관동의</p>
      <Table>
        <thead>
          <tr>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody style={{ textAlign: 'left' }}>
          <h2 style={{ textAlign: 'left' }}>이용약관</h2>
          <Textarea
            id="memberArea_1"
            name="memberArea_1"
            value={
              '제2장 이용 신청 및 승낙제 5 조 (이용계약의 성립) 서비스 가입 신청시 본 약관을 읽고 동의함 버튼을 누르면 이 약관에 동의하는 것으로 간주합니다. 이용계약은 서비스 이용희망자의 이용약관 동의 후 이용신청에 대하여 회사가 승낙함으로써 성립합니다. 이용계약의 성립시기는 회사가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.'
            }
          />
          <br />
          <Checkbox
            id="memberCheck_1"
            name="memberCheck_1"
            label="동의합니다."
          />
          <br />
          <br />
          <h2 style={{ textAlign: 'left' }}>개인 정보 취급 방침</h2>
          <Textarea
            id="memberArea_2"
            name="memberArea_2"
            value={'개인 정보 취급 방침 내용 작성...'}
          />
          <br />
          <Checkbox
            id="memberCheck_2"
            name="memberCheck_2"
            label="동의합니다."
          />
          <br />
          <br />
          <h2 style={{ textAlign: 'left' }}>개인정보 취급 위탁 동의서</h2>
          <Textarea
            id="memberArea_3"
            name="memberArea_3"
            value={'개인정보 취급 위탁 동의서 내용 작성...'}
          />
          <br />
          <Checkbox
            id="memberCheck_3"
            name="memberCheck_3"
            label="동의합니다."
          />
          <br />
          <br />
          <br />
          <br />
          <div style={{ textAlign: 'center' }}>
            <Button color="primary" onClick={() => buttonHandler('agree')}>
              동의합니다.
            </Button>
            &nbsp;
            <Button color="light" onClick={() => buttonHandler('cancle')}>
              동의하지 않습니다.
            </Button>
          </div>
        </tbody>
      </Table>
    </div>
  )
}

export default Member
