import '@/styles/sample/swiper.css'
import { useState } from 'react'
import styled from 'styled-components'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'

export interface BannerSwiperProps {
  bannerList: Array<BannerInfo>
}

interface BannerInfo {
  id: string
  title: string
  imgUrl?: string
}

const BannerSwiper = ({ bannerList }: BannerSwiperProps) => {
  const [, setActiveIndex] = useState(0)

  return (
    <BannerSwiperBox>
      <Swiper
        slidesPerView={1}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        speed={500}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        onSlideChange={(e: SwiperClass) => setActiveIndex(e.activeIndex)}
      >
        {bannerList &&
          bannerList.map((banner: BannerInfo) => (
            <SwiperSlide key={banner.id}>
              <img src={`images/sample/${banner.imgUrl}`} alt={banner.title} />
            </SwiperSlide>
          ))}
      </Swiper>
    </BannerSwiperBox>
  )
}

const BannerSwiperBox = styled.div`
  width: 100%;
  overflow: hidden;
`

export default BannerSwiper
