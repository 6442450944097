import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Table,
} from '@/components'

export const CommentsDetailBox = () => {
  const data = [
    {
      author: '대박나서땅사자',
      date: '2024.11.30 16:12:08',
      contents: '가나다라마바사',
    },
    {
      author: '최안남',
      date: '2024.11.30 16:12:08',
      contents: 'ABCDEFG',
    },
    {
      author: '두꺼비똥',
      date: '2024.11.30 16:12:08',
      contents: 'ㅅㅎㄱㄱㄱㄱ',
    },
    {
      author: 'neverbeBro',
      date: '2024.11.30 16:12:08',
      contents: 'ㄱㄱㄱㄱㄱㄱㄱㄱㄱㄱ',
    },
  ]
  return (
    <>
      {data.map((item, index) => (
        <>
          <div
            style={{
              padding: '5px',
              border: '1px solid #c0ccda', // 전체 테두리 설정
              height: 'auto',
              borderRadius: '4px',
              marginBottom: '20px',
            }}
          >
            <div key={item.author}>
              <div style={{ display: 'flex' }}>
                <div style={{ textAlign: 'left', fontWeight: 'bold' }}>
                  {item.author}
                </div>
                <div
                  style={{
                    textAlign: 'right',
                    marginLeft: 'auto',
                    marginRight: '20px',
                  }}
                >
                  {item.date}
                </div>
              </div>
            </div>
            <div style={{ textAlign: 'left' }}>{item.contents}</div>
          </div>
        </>
      ))}
    </>
  )
}

export const ComentsInputBox = () => {
  return (
    <div
      style={{
        marginBottom: '10px',
      }}
    >
      <InputGroup>
        <Input type="text" id="f_name" name="f_name" />
        <InputGroupAddon>
          <Button variant="outlined" color="light">
            댓글쓰기
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </div>
  )
}
