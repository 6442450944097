import FormGroup from '@/components/forms/form-elements/form-group'
import Input from '@/components/forms/form-elements/input'
import Label from '@/components/forms/form-elements/label'
import Button from '@/components/ui/button/button'
import { useAxios } from '@/context/axiosContext'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { login } from '@/redux/slices/auth'
import { hasKey } from '@/shared/methods'
import { SubmitHandler, useForm } from 'react-hook-form'
import { StyledLabelWrap, StyledWrap } from './style'

interface IFormValues {
  id: string
  password: string
}

const SigninForm = () => {
  const axios = useAxios()

  const dispatch = useAppDispatch()
  const { isAuthenticated, user } = useAppSelector((state) => state.auth)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>()

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    handleLogin(data)
  }

  const handleLogin = async (data: IFormValues) => {
    const reqData: IFormValues = {
      id: data.id,
      password: data.password,
    }

    try {
      const response = await axios.post('/auth/login', reqData)
      console.log(response.data)

      const { accessToken } = response.data
      // 토큰 저장
      if (accessToken) {
        sessionStorage.setItem('Access-Token', accessToken)
        // refresh
        dispatch(
          login({
            id: data.id,
            name: data.id,
          }),
        )
      }
    } catch (error) {
      console.error('Error during POST request:', error)
    }
  }

  return (
    <StyledWrap>
      <form action="#" onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="id">
            ID
          </Label>
          <Input
            type="text"
            id="id"
            placeholder="neverbe"
            feedbackText={errors?.id?.message}
            state={hasKey(errors, 'id') ? 'error' : 'success'}
            showState={!!hasKey(errors, 'id')}
            {...register('id', {
              required: 'ID를 입력하세요.',
              // pattern: {
              //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              //   message: 'invalid email address',
              // },
            })}
          />
        </FormGroup>
        <FormGroup mb="20px">
          <StyledLabelWrap>
            <Label display="block" mb="5px" htmlFor="password">
              비밀번호
            </Label>
          </StyledLabelWrap>
          <Input
            id="password"
            type="password"
            placeholder="qwer1234"
            feedbackText={errors?.password?.message}
            state={hasKey(errors, 'password') ? 'error' : 'success'}
            showState={!!hasKey(errors, 'password')}
            {...register('password', {
              required: '비밀번호를 입력하세요.',
              minLength: {
                value: 6,
                message: 'Minimum length is 6',
              },
              maxLength: {
                value: 10,
                message: 'Minimum length is 10',
              },
            })}
          />
        </FormGroup>
        <Button type="submit" color="brand2" fullwidth>
          로그인
        </Button>
      </form>
    </StyledWrap>
  )
}

export default SigninForm
