import Button from '@/components/ui/button/button'
import Modal from '@/components/ui/modal/modal'
import ModalBody from '@/components/ui/modal/modal-body'
import ModalFooter from '@/components/ui/modal/modal-footer'
import { useEffect, useState } from 'react'
import { BoardCrudBar } from './boardCrudBar'

export const BoardHeader = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (show) {
      document.body.classList.add('doar-modal-open')
    } else {
      document.body.classList.remove('doar-modal-open')
    }
    return () => {
      document.body.classList.remove('doar-modal-open')
    }
  }, [show])

  return (
    <div style={{ display: 'flex' }}>
      <h1>자유게시판</h1>
    </div>
  )
}
