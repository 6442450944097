import SignupForm from '@/components/member/signup-form'
import {
  StyledImage,
  StyledImgText,
  StyledMedia,
  StyledMediaBody,
  StyledSignin,
} from '@/components/member/signup/style'

const NewMember = () => {
  return (
    <StyledMedia>
      <StyledSignin>
        <SignupForm />
      </StyledSignin>
    </StyledMedia>
  )
}

export default NewMember
