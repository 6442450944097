import React from 'react'
import { Link } from 'react-router-dom'
import { routes } from '@/routes/routes'

const GNB: React.FC = () => {
  return (
    <nav
      style={{
        padding: '10px',
        backgroundColor: '#f8f9fa',
        borderBottom: '1px solid #ddd',
      }}
    >
      <ul style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0 }}>
        {routes
          .filter((route) => route.label) // label이 있는 라우트만 필터링
          .map((route, index) => (
            <li key={index} style={{ margin: '0 15px' }}>
              <Link
                to={route.path}
                style={{ textDecoration: 'none', color: '#007bff' }}
              >
                {route.label}
              </Link>
            </li>
          ))}
      </ul>
    </nav>
  )
}

export default GNB
