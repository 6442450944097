import Input from '@/components/forms/form-elements/input'
import Textarea from '@/components/forms/form-elements/textarea'
import { InputGroup, InputGroupAddon, Modal, ModalBody } from '@/components'
import { BoardHeader } from '../_components/boardHeader'
import { BoardCrudBar } from '../_components/boardCrudBar'
import { useAxios } from '@/context/axiosContext'
import { useState } from 'react'
import Button from '@/components/ui/button/button'
import { useNavigate } from 'react-router-dom'
import ModalFooter from '@/components/ui/modal/modal-header'

type TInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement

const InputBoard: React.FC = () => {
  const axios = useAxios()
  const navigate = useNavigate()
  const [postResponse, setPostResponse] = useState<any>(null)
  const [loading, setLoading] = useState(false) // 로딩 상태
  const [error, setError] = useState<string | null>(null) // 에러 상태
  const [formData, setFormData] = useState({
    nttSj: '',
    nttCn: '',
    regId: '',
  })

  // Modal 상태
  const [show, setShow] = useState(false)
  const [modalMessage, setModalMessage] = useState('') // Modal 메시지 상태

  //input 값 변경 핸들러
  const handleChange = (e: React.ChangeEvent<TInput>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement // 타입 캐스팅
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  //TODO 취소버튼 클릭시 로직 추가
  const backToBoard = () => {}
  //폼 제출 핸들러
  const handleSubmit = async (e: React.FormEvent) => {
    console.log('handleSubmit')
    e.preventDefault()

    if (!formData.nttSj || !formData.nttCn) {
      alert('내용을 입력하세요.')
      return
    }

    setLoading(true)
    setError(null)

    try {
      const response = await axios.post('/insert', formData) // POST 요청
      setPostResponse(response.data) // 서버에서 받은 응답을 상태에 저장
      console.log('서버 응답:', response.data)
      // 성공 시 Modal 메시지 설정
      setModalMessage('저장하시곘습니까?')
      setShow(true)
    } catch (err: any) {
      console.error(err)
      setError(err.response?.data?.message || '알 수 없는 오류가 발생했습니다.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <BoardHeader />
      </div>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            marginBottom: '10px',
          }}
        >
          <Input
            id="nttSj"
            name="nttSj"
            placeholder="제목을 입력하세요."
            value={formData.nttSj}
            onChange={handleChange}
          />
        </div>
        <div
          style={{
            marginBottom: '10px',
          }}
        >
          <Textarea
            id="nttCn"
            name="nttCn"
            placeholder="내용을 입력하세요."
            state="success"
            value={formData.nttCn}
            onChange={handleChange}
          />
        </div>
        <InputGroup>
          <Input type="text" id="f_name" name="f_name" />
          <InputGroupAddon>
            <Button variant="outlined" color="light">
              파일첨부
            </Button>
          </InputGroupAddon>
        </InputGroup>
        <div
          style={{
            marginBottom: '10px',
          }}
        ></div>
        <div style={{ display: 'flex' }}>
          <div style={{ marginLeft: 'auto' }}>
            <div style={{ display: 'flex', gap: '5px' }}>
              <Button type="submit" variant="outlined" color="primary">
                저장
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate('/board')}
              >
                취소
              </Button>
            </div>
          </div>
        </div>

        <Modal show={show} onClose={() => setShow(false)} size="sm">
          <ModalBody>{modalMessage}</ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() =>
                setTimeout(() => {
                  setShow(false)
                  navigate('/board')
                }, 300)
              }
            >
              저장
            </Button>
            <Button
              color="secondary"
              onClick={() =>
                setTimeout(() => {
                  setShow(false)
                  navigate('/board')
                }, 300)
              }
            >
              취소
            </Button>
          </ModalFooter>
        </Modal>
      </form>
    </>
  )
}

export default InputBoard
