import {
  Tab,
  TabContent,
  TabList,
  TabPanel,
  TabWrap,
} from '@/components/ui/tab/tab'
import { useAxios } from '@/context/axiosContext'
import { useEffect, useState } from 'react'

interface ReqBoardType {
  type?: string
}

interface ResBoardDataType {
  id: string
  date: string
  title: string
}

interface BoardDataType {
  type: string
  boardList: Array<ResBoardDataType>
}

export const MainBoard = () => {
  const axios = useAxios()
  const [boardData, setBoardData] = useState<BoardDataType>({
    type: 'notice',
    boardList: [],
  })
  const [tabIdx, setTabIdx] = useState<number>(0)

  useEffect(() => {
    // getBoard({ type: 'notice' })
  }, [])

  const handleChangeTab = (index: number, last: number, event: Event) => {
    setTabIdx(index)
  }

  const getBoard = async (type?: ReqBoardType) => {
    try {
      const reqData: ReqBoardType = {
        type: 'notice',
      }
      const response = await axios.get('/get-boardList', {
        params: { ...reqData },
      })
      setBoardData(response.data)
    } catch (error) {
      console.error('Error during GET request:', error)
    }
  }

  return (
    <TabWrap selectedIndex={tabIdx} onSelect={handleChangeTab}>
      <TabList>
        <Tab>공지사항</Tab>
        <Tab>자유게시판</Tab>
      </TabList>
      <TabContent>
        <TabPanel>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            {[...new Array(3)].map((_, i) => (
              <div key={i} style={{ display: 'flex', gap: '5px' }}>
                <span>2024.11.28</span>
                <span>title {i}</span>
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            {[...new Array(3)].map((_, i) => (
              <div key={i} style={{ display: 'flex', gap: '5px' }}>
                <span>2024.12.28</span>
                <span>
                  {i} title {i}
                </span>
              </div>
            ))}
          </div>
        </TabPanel>
        {/* <TabPanel>
          {boardData.type === 'notice' &&
            boardData.boardList.length > 0 &&
            boardData.boardList.map((item: ResBoardDataType, i) => {
              return (
                <div key={i} style={{ display: 'flex', gap: '5px' }}>
                  <span>{item.date}</span>
                  <span>{item.title}</span>
                </div>
              )
            })}
        </TabPanel>
        <TabPanel>
          {boardData.type === 'board' &&
            boardData.boardList.length > 0 &&
            boardData.boardList.map((item: ResBoardDataType, i) => {
              return (
                <div key={i} style={{ display: 'flex', gap: '5px' }}>
                  <span>{item.date}</span>
                  <span>{item.title}</span>
                </div>
              )
            })}
        </TabPanel> */}
      </TabContent>
    </TabWrap>
  )
}
