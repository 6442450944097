import Button from '@/components/ui/button/button'
import Modal from '@/components/ui/modal/modal'
import ModalBody from '@/components/ui/modal/modal-body'
import ModalFooter from '@/components/ui/modal/modal-footer'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const BoardCrudBar = ({ type }: { type: string }) => {
  const [show, setShow] = useState(false)
  const navigate = useNavigate()
  const isLogin = true

  const buttonHandler = (pathName: string) => {
    console.log('pathName:', pathName)
    if (isLogin) {
      switch (pathName) {
        case 'write':
          return navigate('/board/write')
        case 'modify':
          return navigate('/board/modify')
        case 'list':
          return navigate('/board')
        default:
          return navigate('/board')
      }
    }
    setShow((prev) => !prev)
  }

  useEffect(() => {
    if (show) {
      document.body.classList.add('doar-modal-open')
    } else {
      document.body.classList.remove('doar-modal-open')
    }
    return () => {
      document.body.classList.remove('doar-modal-open')
    }
  }, [show])

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ marginLeft: 'auto' }}>
        <>
          {/* <Modal show={show} onClose={() => setShow(false)} size="sm">
            <ModalBody>
              <p>로그인 후에 글 쓰기가 가능합니다.</p>
            </ModalBody>

            <ModalFooter>
              <Button color="secondary" onClick={() => setShow(false)}>
                닫기
              </Button>
              <Button color="primary" onClick={() => setShow(false)}>
                로그인
              </Button>
            </ModalFooter>
          </Modal> */}
        </>
        <div style={{ display: 'flex', gap: '5px' }}>
          {type === 'write' && (
            <Button onClick={() => buttonHandler('write')}>글쓰기</Button>
          )}
          {type === 'modify' && (
            <Button onClick={() => buttonHandler('modify')}>수정</Button>
          )}
          {type === 'modify' && (
            <Button onClick={() => buttonHandler('delete')}>삭제</Button>
          )}
          {type === 'modify' && (
            <Button onClick={() => buttonHandler('list')}>목록</Button>
          )}
        </div>
      </div>
    </div>
  )
}
