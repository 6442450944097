import Media from '@/components/ui/media/media'
import MediaBody from '@/components/ui/media/media-body'
import styled, { device } from '@/shared/styled'

export const StyledMedia = styled(({ ...rest }) => <Media {...rest} />)`
  position: relative;
  height: 100%;
  align-items: stretch;
  justify-content: center;
`

export const StyledMediaBody = styled(({ ...rest }) => <MediaBody {...rest} />)`
  align-items: center;
  display: none;
  ${device.large} {
    display: flex;
  }
`

export const StyledImage = styled.div`
  width: 600px;
`

export const StyledImgText = styled.div`
  font-size: 12px;
  text-align: center;
  left: 0px;
  bottom: -10px;
  position: absolute;
`

export const StyledSignin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin-right: 20px;
`

export const StyledContainer = styled.div`
  width: 300px;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  font-family: Arial, sans-serif;
  border: 1px solid rgb(192, 204, 218);
`

export const StyledTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  line-height: 1.5;
  p {
    margin: 0;
    font-size: 14px;
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`

export const StyledDivider = styled.div`
  minwidth: 1.5px;
  margin: 6px 0;
  background: #8392a5;
`
