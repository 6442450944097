import { Table } from '@/components'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAxios } from '@/context/axiosContext'

interface GetData {
  srchOpt?: string
  srchStr?: string
  page?: number
  cmpnyCd?: string
  bbsTy: string
}

export const BoardList = () => {
  const navigate = useNavigate()
  const axios = useAxios()
  const [boardList, setBoardList] = useState([])
  const [pageList, setPageList] = useState([])
  const [currentPage, setCurrentPage] = useState(0) //현재 페이지 세팅
  const [prevBlock, setPrevBlock] = useState(0) //이전 페이지 블록
  const [nextBlock, setNextBlock] = useState(0) //다음 페이지 블록
  const [lastPage, setLastPage] = useState(0) //마지막 페이지

  // API 호출을 위한 useEffect 훅
  useEffect(() => {
    // API 호출 함수
    const getBoardList = async () => {
      try {
        const data: GetData = {
          cmpnyCd: '',
          bbsTy: '01',
        }
        const response = await axios.get('/list', { params: { ...data } }) // 적절한 API 경로로 변경
        setBoardList(response.data) // API 응답 데이터로 boardList 상태 업데이트
        console.log('boardList:', boardList)
      } catch (err: any) {
        console.error('Error fetching board list:', err)
      }
    }

    getBoardList() // 컴포넌트 마운트 시 API 호출
  }, []) // 빈 배열을 넣으면 처음 한 번만 호출됨

  return (
    <Table hover={true} bordered={true}>
      <thead>
        <tr>
          <th scope="col">NO.</th>
          <th scope="col">제목</th>
          <th scope="col">작성자</th>
          <th scope="col">작성일</th>
          <th scope="col">조회수</th>
        </tr>
      </thead>
      <tbody>
        {/* {boardList.map((item, index) => (
          <tr key={item.}>
            <td>{index + 1}</td>
            <td style={{ textAlign: 'left' }}>{item.title}</td>
            <td>{item.author}</td>
            <td>{item.date}</td>
            <td>{item.views}</td>
          </tr>
        ))} */}
      </tbody>
    </Table>
  )
}
