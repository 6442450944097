import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import AppRoutes from '@/routes/AppRoutes'
import Layout from '@/components/common/layout'

function App() {
  return (
    <Router>
      <Layout>
        <AppRoutes />
      </Layout>
    </Router>
  )
}

export default App
