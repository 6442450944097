import {
  Label,
  FormGroup,
  Input,
  Anchor,
  Button,
  Text,
  Row,
  Select,
  Col,
  InputGroupAddon,
  InputGroup,
} from '@/components'
import Radio from '@/components/forms/form-elements/radio'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { hasKey } from '@/shared/methods'
import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledDivider,
  StyledBottomText,
} from './style'
import { fontSize, width } from 'styled-system'
import { useState } from 'react'
import { AlignCenter } from 'react-feather'

interface IFormValues {
  id: string
  password: string
  name: string
  last_name: string
}

const SignCompleteForm = () => {
  const navigate = useNavigate()
  const isLogin = true

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>()

  const [selectedEmail, setSelectedEmail] = useState('')

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    alert(JSON.stringify(data, null))
  }

  const selectHandler = (data: any) => {
    console.log('data:', data)
    setSelectedEmail(data.target.value)
  }

  const buttonHandler = (pathName: string) => {
    console.log('pathName:', pathName)
    if (isLogin) {
      switch (pathName) {
        case 'complete':
          return navigate('/member/completeMember')
        case 'cancle':
          return navigate('/main')
        default:
          return navigate('/member')
      }
    }
  }

  return (
    <StyledWrap>
      <StyledTitle>네버비 회원가입</StyledTitle>
      <StyledDesc>회원가입 - 가입완료 </StyledDesc>
      <form action="#" onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormGroup mb="20px">
          <Label display="block" mb="5px" htmlFor="id">
            회원가입이 완료되었습니다.
          </Label>
          <Input
            id="id"
            name="id"
            type="id"
            placeholder="아이디를 입력해 주세요."
          />
        </FormGroup>

        <FormGroup mb="20px" margin={'AlignCenter'}>
          <Button
            type="submit"
            color="primary"
            width="100px"
            onClick={() => buttonHandler('complete')}
          >
            확인
          </Button>
        </FormGroup>
        <StyledBottomText>
          Don&apos;t have an account? <Anchor path="/signin">Sign In</Anchor>
        </StyledBottomText>
      </form>
    </StyledWrap>
  )
}

export default SignCompleteForm
