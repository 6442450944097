import { MainContent } from '@/components/main/mainContent'
import { SigninContainer } from '@/components/main/signin/signin'
import React from 'react'
import styled from 'styled-components'

const Main: React.FC = () => {
  return (
    <MainWrapper>
      <SigninContainer />
      <MainContent />
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  display: flex;
  padding: 20px;
`

export default Main
