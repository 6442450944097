import BannerSwiper, {
  BannerSwiperProps,
} from '@/components/main/banner/bannerSwiper'
import { useAxios } from '@/context/axiosContext'
import { useEffect, useState } from 'react'

export const MainBanner = () => {
  const axios = useAxios()

  const [bannerList, setBannerList] = useState<BannerSwiperProps['bannerList']>(
    [],
  )

  const getBannerData = async () => {
    try {
      const response = await axios.get('/get-mainBannerList')
      setBannerList(response.data)
    } catch (error) {
      console.error('Error during GET request:', error)
    }
  }

  useEffect(() => {
    getBannerData()
  }, [])

  return (
    <>
      {bannerList && bannerList.length > 0 ? (
        <BannerSwiper bannerList={bannerList} />
      ) : (
        <BannerSwiper
          bannerList={[
            { id: '1', title: 'title1', imgUrl: 'img2.jpg' },
            { id: '2', title: 'title2', imgUrl: 'img3.jpg' },
            { id: '3', title: 'title3', imgUrl: 'img4.jpg' },
          ]}
        />
      )}
    </>
  )
}
