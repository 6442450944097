import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface User {
  id: string
  name: string
}

export interface AuthState {
  isAuthenticated: boolean // 로그인 여부
  user?: User // 로그인한 사용자 정보
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: undefined,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<User>) {
      state.isAuthenticated = true
      state.user = action.payload
    },
    logout(state) {
      state.isAuthenticated = false
      state.user = undefined
    },
  },
})

export const { login, logout } = authSlice.actions
export default authSlice.reducer
