import { MainBanner } from '@/components/main/banner/mainBanner'
import { MainEvent } from '@/components/main/event/mainEvent'
import { MainBoard } from '@/components/main/board/mainBoard'
import styled from 'styled-components'

export const MainContent = () => {
  return (
    <MainContentWrapper>
      <MainBanner />
      <MainBoard />
      {/* <MainEvent /> */}
    </MainContentWrapper>
  )
}

const MainContentWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
