import React from 'react'
import ReactDOM from 'react-dom/client'
import '@/index.css'
import App from '@/App'
import reportWebVitals from '@/reportWebVitals'
import { AxiosProvider } from '@/context/axiosContext'
import { Provider } from 'react-redux'
import PersistProvider from '@/redux/providers/persist-provider'
import ThemeProvider from '@/redux/providers/theme-provider'
import { store } from '@/redux/store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistProvider>
        <ThemeProvider>
          <AxiosProvider>
            <App />
          </AxiosProvider>
        </ThemeProvider>
      </PersistProvider>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
