import { Button, Nav, Text } from '@/components'
import SigninForm from '@/components/main/signin/signin-form/signinForm'
import NavLink from '@/components/ui/nav/nav-link'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { logout } from '@/redux/slices/auth'

import { useAxios } from '@/context/axiosContext'
import {
  StyledButtonContainer,
  StyledContainer,
  StyledDivider,
  StyledMedia,
  StyledSignin,
  StyledTextContainer,
} from './style'

export const SigninContainer = () => {
  const dispatch = useAppDispatch()
  const axios = useAxios()

  const { isAuthenticated, user } = useAppSelector((state) => state.auth)

  const handleLogout = async () => {
    try {
      const response = await axios.post('/auth/logout')
      console.log(response.data)
      sessionStorage.removeItem('Access-Token')
      dispatch(logout())
    } catch (error) {
      console.error('Error during POST request:', error)
    }
  }

  return (
    <StyledMedia>
      <StyledSignin>
        {isAuthenticated ? (
          <StyledContainer>
            <StyledTextContainer>
              <Text>{user?.id} 님</Text>
              <Text>쪽지 : N개</Text>
            </StyledTextContainer>
            <StyledButtonContainer>
              <Button width="100%" color="white">
                마이페이지
              </Button>
              <Button onClick={handleLogout} width="100%" color="white">
                로그아웃
              </Button>
            </StyledButtonContainer>
          </StyledContainer>
        ) : (
          <>
            <SigninForm />
            <div style={{ marginTop: '5px' }}>
              <Nav>
                <NavLink path={'#'}>아이디/비밀번호 찾기</NavLink>
                <StyledDivider />
                <NavLink path={'/member'}>회원가입</NavLink>
              </Nav>
            </div>
          </>
        )}
      </StyledSignin>
    </StyledMedia>
  )
}
