import React from 'react'
import Header from '@/components/common/header'
import Footer from '@/components/common/footer'

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
    >
      <Header />
      <main style={{ flex: 1, padding: '20px' }}>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
