import styled, { device } from '@/shared/styled'
import { Media, MediaBody } from '@/components'

export const StyledMedia = styled(({ ...rest }) => <Media {...rest} />)`
  height: 100%;
  align-items: stretch;
  justify-content: left;
`

export const StyledMediaBody = styled(({ ...rest }) => <MediaBody {...rest} />)`
  display: none;
  ${device.large} {
    display: flex;
    position: relative;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
  }
  ${device.xlarge} {
    padding-left: 60px;
    padding-right: 60px;
  }
`

export const StyledImage = styled.div``

export const StyledImgText = styled.div`
  font-size: 12px;
  text-align: center;
  right: 0px;
  bottom: -10px;
  position: absolute;
`

export const StyledSignin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 960px;
  ${device.large} {
    margin-right: 50px;
  }
  ${device.xlarge} {
    margin-right: 60px;
  }
`
