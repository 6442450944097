import { Button, Input } from '@/components'
import image from '@/shared/images/img19.png'
import {
  StyledWrap,
  StyledImg,
  StyledTitle,
  StyledSubTitle,
  StyledDesc,
  StyledResetForm,
  StyledNote,
} from './style'
import Content from '@/components/layout/content'

const ErrorContainer = () => {
  return (
    <Content fullHeight align="center">
      <StyledWrap>
        <StyledImg>
          <img src={image} alt="404" />
        </StyledImg>
        <StyledTitle>404 Page Not Found</StyledTitle>
        <StyledSubTitle>요청하신 페이지는 존재하지 않습니다.</StyledSubTitle>
        <StyledDesc>
          주소를 잘못 입력했거나, 페이지가 이동했을 수 있습니다. 다시
          확인해주세요.
        </StyledDesc>
        <StyledNote>
          Error page concept with laptop vector is created by{' '}
          <a
            href="https://www.freepik.com/free-photos-vectors/background"
            target="_blank"
            rel="noopener noreferrer"
          >
            freepik (freepik.com)
          </a>
        </StyledNote>
      </StyledWrap>
    </Content>
  )
}

export default ErrorContainer
