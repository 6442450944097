import React from 'react'
import GNB from '@/components/common/gnb'

const Header: React.FC = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src={`images/logo-neverbe.webp`}
          alt={'logo'}
          width={'60%'}
          height={240}
        />
      </div>
      <GNB />
    </div>
  )
}

export default Header
