import Table from '@/components/ui/table/table'
import React, { useEffect, useState } from 'react'
import Pagination from './_components/pagination'
import { textAlign } from 'styled-system'
import { BoardCrudBar } from './_components/boardCrudBar'
import { BoardHeader } from './_components/boardHeader'
import { BoardList } from './list/boardList'

const Board: React.FC = () => {
  const handlePageChange = (page: number) => {
    console.log('Current page:', page)
  }

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <div style={{ display: 'flex' }}>
        <BoardHeader />
        <div style={{ marginLeft: 'auto' }}>
          <BoardCrudBar type={'write'} />
        </div>
      </div>

      <BoardCrudBar type={'write'} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          listStyle: 'none',
          padding: 0,
          margin: '20px 0',
        }}
      >
        <BoardList />
        <Pagination
          totalPages={10}
          onPageChange={handlePageChange}
          //data={data}
        />
      </div>
    </div>
  )
}

export default Board
