import { useState } from 'react'

interface PaginationProps {
  totalPages: number
  onPageChange: (page: number) => void // 타입 정의
  data?: []
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  onPageChange,
}) => {
  const perPage = 5 //한페이지에 보여줄 항목 수
  const [currentPage, setCurrentPage] = useState(1)

  //페이지 데이터 계산
  const startIndex = (currentPage - 1) * perPage
  //const currentData = data.slice(startIndex, startIndex + perPage)

  // 페이지 수 계산
  //const totalPages = Math.ceil(data.length / perPage)

  const handlePageClick = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page)
      onPageChange(page) // 페이지 변경 콜백
    }
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  return (
    <div style={styles.pagination}>
      {/* Previous button */}
      <button
        style={styles.button}
        onClick={() => handlePageClick(currentPage - 1)}
        disabled={currentPage === 1}
      >
        «
      </button>

      {/* Page numbers */}
      {Array.from({ length: totalPages }, (_, index) => (
        <button
          key={index + 1}
          style={{
            ...styles.button,
            ...(currentPage === index + 1 ? styles.activeButton : {}),
          }}
          onClick={() => handlePageClick(index + 1)}
        >
          {index + 1}
        </button>
      ))}

      {/* Next button */}
      <button
        style={styles.button}
        onClick={() => handlePageClick(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        »
      </button>
    </div>
  )
}

const styles = {
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginTop: '20px',
  },
  button: {
    padding: '10px 15px',
    fontSize: '16px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: 'white',
    cursor: 'pointer',
    transition: 'all 0.3s',
  },
  activeButton: {
    backgroundColor: '#007bff',
    color: 'white',
    fontWeight: 'bold',
  },
}

export default Pagination
